import React from 'react';
import { Link } from 'react-router-dom';

export default function Archive() {
  return (
    <div className='grSection'>

        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <h1>Archiwum newslettera</h1>
              
              <div className='grArchive'>
              <div className='grArchive__item'>
                    Newsletter #16, październik 2024
                  <Link className='grButton' to="/numer16"> zobacz</Link>
                </div>
              <div className='grArchive__item'>
                    Newsletter #15, wrzesień 2024
                  <Link className='grButton' to="/numer15"> zobacz</Link>
                </div>
              <div className='grArchive__item'>
                    Newsletter #14, lipiec 2024
                  <Link className='grButton' to="/numer14"> zobacz</Link>
                </div>
                 <div className='grArchive__item'>
                    Newsletter #13, czerwiec 2024
                  <Link className='grButton' to="/numer13"> zobacz</Link>
                </div>
              <div className='grArchive__item'>
                    Newsletter #12, kwiecień 2024
                  <Link className='grButton' to="/numer12"> zobacz</Link>
                </div>
              <div className='grArchive__item'>
                    Newsletter #11, marzec 2024
                  <Link className='grButton' to="/numer11"> zobacz</Link>
                </div>
                 <div className='grArchive__item'>
                    Newsletter #10, luty 2024
                  <Link className='grButton' to="/numer10"> zobacz</Link>
                </div>
              <div className='grArchive__item'>
                    Newsletter #9, styczeń 2024
                  <Link className='grButton' to="/numer9"> zobacz</Link>
                </div>
                 <div className='grArchive__item'>
                    Newsletter #8, grudzień 2023
                  <Link className='grButton' to="/numer8"> zobacz</Link>
                </div>
                <div className='grArchive__item'>
                    Newsletter #7, październik 2023
                  <Link className='grButton' to="/numer7"> zobacz</Link>
                </div>
              <div className='grArchive__item'>
                    Newsletter #6, wrzesień 2023
                  <Link className='grButton' to="/numer6"> zobacz</Link>
                </div>
              <div className='grArchive__item'>
                    Newsletter #5, lipiec 2023
                  <Link className='grButton' to="/numer5"> zobacz</Link>
                </div>
              <div className='grArchive__item'>
                    Newsletter #4, czerwiec 2023
                  <Link className='grButton' to="/numer4"> zobacz</Link>
                </div>
              <div className='grArchive__item'>
                    Newsletter #3, maj 2023
                  <Link className='grButton' to="/numer3"> zobacz</Link>
                </div>
                <div className='grArchive__item'>
                    Newsletter #2, kwiecień 2023
                  <Link className='grButton' to="/numer2"> zobacz</Link>
                </div>
                <div className='grArchive__item'>
                  Newsletter #1, kwiecień 2023
                  <Link className='grButton' to="/numer1"> zobacz</Link>
                </div>  
              </div>
            </div>
          </div>
        </div>
        
    </div>
  )
}
