import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';
import Youtube from '../../elements/Youtube';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue17/grenevia-9-glowne.jpg';
import pic1 from '../../assets/issue17/1_grenevia.jpg';
import pic2 from '../../assets/issue17/2_famur.jpg';
import pic3 from '../../assets/issue17/3_elgor.jpg';
import pic4 from '../../assets/issue17/4_pst.jpg';
import pic5 from '../../assets/issue17/5_impact.jpg'


import mb from '../../assets/issue9/mbendzera.jpg'
import pm from '../../assets/issue9/pmajcherkiewicz.jpg'
import bz from '../../assets/issue12/bzawiszowska.jpg'
import tj from '../../assets/issue14/jakubowski.jpg'

export default function Issue2() {

  const _links = [
    {label: "Grenevia SA zwiększa udziały w Projekt Solartechnik SA", target: "section1"},
    {label: "Zmiany w zarządzie FAMUR SA", target: "section2"},
    {label: "Elgór + Hansen z nowością w portfolio", target: "section3"},
    {label: "82,5 mln zł na budowę farm PV dla PST", target: "section4"},
    {label: "IMPACT na Hydrogen Day 2024", target: "section5"},
  ];

  const _title = "Newsletter #9(17), grudzień 2024";
  const _aside1 =
    <Aside image={bz} theme='light'>
      <p>– Grupa Grenevia kontynuowała swoją działalność, skupiając się na rozwoju czterech segmentów biznesowych, co przełożyło się na wzrost przychodów operacyjnych w każdym z nich. Naszym priorytetem pozostaje tworzenie wartości we wszystkich segmentach, z naciskiem na wspieranie transformacji w kierunku gospodarki niskoemisyjnej – komentuje <strong>prezes Grenevia SA Beata Zawiszowska</strong>.</p>
    </Aside>;

    const _aside2 =
    <Aside image={bz} theme='light'>
      <p>– Umowa zawarta z konsorcjum banków stanowi ważny krok z punktu widzenia dalszego rozwoju Grupy Grenevia. Pozwala nam na zapewnienie średnioterminowego, elastycznego finansowania, które będzie wspierać zarówno tradycyjną część naszego biznesu, jak i zieloną transformację pozostałych segmentów – mówi <strong>prezes Grenevia SA Beata Zawiszowska</strong>.</p>
    </Aside>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Coraz bliżej Święta, coraz bliżej Święta…</h1>
        <div>
          <p>Drogi #TeamGrenevia,</p><p>
niech zbliżające się Święta Bożego Narodzenia naładują Was pozytywną energią i przywieją wspomnienia pełne dziecięcej radości. W Nowy Roku życzymy mocy do działania i wielu słonecznych chwil!</p>
<p>Wesołych Świąt!</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="light">

<h1>Grenevia SA<span className="grText"> zwiększa udziały w Projekt Solartechnik SA</span></h1>


<p><strong>Grenevia zwiększa swoje udziały w Projekt Solartechnik do 99,6%. To wynik umów z akcjonariuszami mniejszościowymi tej spółki, które zawarto 29 listopada br.</strong></p>
<Image pic={pic1} />

<p>Umożliwi to rewizję kierunków strategicznych rozwoju segmentu OZE Grupy Grenevia w kontekście dynamicznych zmian rynkowych, m.in. przez skupienie się na procesie dewelopowania projektów OZE na rynku polskim.</p>
<p>Wykup obejmuje akcje Fundacji Rodzinnej Marcjanik i Macieja Marcjanika, który pełnił funkcję prezesa zarządu spółki Projekt Solartechnik, oraz udziały TDJ SA w Famur Solar sp. z o.o. – spółce będącej akcjonariuszem PST.</p>
</TextContainer>


      <TextContainer id="section2" theme="dark">

        <h1>Zmiany<span className="grText"> w zarządzie FAMUR SA</span></h1>
       
        <p><strong>Do zarządu FAMUR SA na stanowisku wiceprezesa ds. finansów dołączył Michał Perlik.</strong></p>
        <Image pic={pic2} />
  
        <p>Michał ma ponad 20 lat doświadczenia w zarządzaniu finansami, a także w obszarach budowania strategii, relacji inwestorskich oraz zrównoważonego rozwoju biznesu. Jest praktykiem zarządzania przez cele, który stawia na budowanie motywacji i zaangażowania podległych zespołów.</p>
        <p>Od 2018 roku był związany z koncernem multienergetycznym Grupą Orlen, zdobywając doświadczenie w obszarach strategii i finansów na kolejnych szczeblach kariery. Przez ostatnie cztery lata, jako dyrektor wykonawczy ds. zarządzania finansami, odpowiadał w Grupie Orlen m.in. za pozyskiwanie finansowania na szereg inwestycji, w tym projekty związane ze zrównoważonym rozwojem, jak np. morskie elektrownie wiatrowe.</p><p>W latach 2022-2024 pełnił stanowisko wiceprezesa zarządu ds. finansowych w Energa SA. W przeszłości był również związany m.in. z PKO Bankiem Hipotecznym, ING Bankiem Śląskim, Grupą PZU czy holdingiem CEPD N.V. zarządzającym siecią Aptek DOZ.</p>
        
        
        </TextContainer>

        <TextContainer id="section3" theme="light">

<h1>Elgór + Hansen<span className="grText"> z nowością w portfolio</span></h1>
<p><strong>Elgór + Hansen wdrożył pierwszą stację dużej mocy FUTURA High Power dla klienta z branży OZE. Stacja, w której moc pojedynczego transformatora wynosi 5500kVA, jest nowością w portfolio produktowym naszej spółki. Cały projekt będzie docelowo składał się z części stacji podzielonych na pary w układzie MASTER-SLAVE na 30MW farmie.</strong></p>
<Image pic={pic3} />
<p>FUTURA High Power to nowoczesna stacja stworzona z myślą o rosnących potrzebach rynku energetycznego. Dzięki modułowej konstrukcji możliwe jest jej łatwa rozbudowa i dopasowanie do indywidualnych potrzeb klienta, zdalne sterowanie i pełna wizualizacja, ale przede wszystkim możliwość zastosowania transformatora o mocy nawet do 9MVA. Konstrukcja stacji dostępna jest zarówno w obudowie metalowej, jak i żelbetowej.
</p>
 </TextContainer>

 <TextContainer id="section4" theme="dark">

<h1>82,5 mln zł <span className="grText">na budowę farm PV dla PST</span></h1>
<p><strong>Projekt Solartechnik otrzyma od Polskiego Funduszu Rozwoju (PFR) pożyczkę w wysokości 82,5 mln zł na finansowanie i refinansowanie kosztów budowy farm fotowoltaicznych o łącznej mocy ok. 40 MW.</strong></p>
<Image pic={pic4} />
<p>Projekty PV realizowane przez Projekt Solartechnik zlokalizowane są w 12 województwach Polski, ponad połowa z nich jest już w procesie budowy.</p>
<p>To kolejna już, udana współpraca Projekt Solartechnik z PFR. Biznesową relację rozpoczęto w czerwcu 2023 r. podpisując umowę pożyczki na finansowanie i refinansowanie kosztów budowy 26 farm fotowoltaicznych o łącznej mocy 43,09 MW.</p>
 </TextContainer>

 <TextContainer id="section5" theme="light">

<h1>IMPACT<span className="grText"> na Hydrogen Day 2024</span></h1>
<p><strong>Zespół IMPACT Clean Power Technology SA wziął na początku grudnia udział w Hydrogen Day 2024, wydarzeniu organizowanym na Politechnice Poznańskiej. Podczas spotkania zaprezentowano ogniwo wodorowe opracowywane przez ekspertów tej uczelni, z systemem bateryjnym IMPACT.</strong></p>
<Image pic={pic5} />
<p>Przedstawiciele naszego segmentu e-mobilności mieli również możliwość wysłuchać wykładu o „Wodorowej przyszłości”.</p>
<p>Hydrogen Day 2024 był zorganizowany przez Instytut Napędów i Lotnictwa oraz Wydział Inżynierii Lądowej i Transportu Politechniki Poznańskiej. Pierwszego dnia goszczono otoczenie społeczno-gospodarcze, a drugi dzień był dedykowany studentom tej uczelni.</p>
 </TextContainer>

    <SiteFooter />

    </>
  )
}
